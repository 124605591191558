.filter-select-wrapper{
  #filter-select-multiple-checkbox{
    padding-top: 6.79px;
    padding-bottom: 8.85px;
  }
  .placeholder-wrapper{
    display: flex;
    align-items: center;
    gap: 10.22px;
    .filter-icon{
       color: #636363
    }
    .filter-heading{
        font-family: poppins, sans-serif ;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #636363;
        padding-top: 2px;
    }
  }
}