#ccnumber input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}

#ccexp input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}

#cvv input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}

#checkname input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}

#checkaba input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}

#checkaccount input {
  border-color: #C4CDD5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  font-family: poppins, sans-serif;
}