
/* .footer-section{
    padding: 50px 139px 20px 140px ;
} */
.logo-sec{
    display: flex; gap: 60px;
    flex-direction: column;
}
.fnt-size{
   
font-size: 18px;
font-weight: 500;
line-height: 24.3px;

}
.logo-img {
    background-color: rgb(255, 255, 255);
    width: 135px;
    height: 90px;
    border-radius: 8px;
    padding: 10px;
}

.service-section {
    gap:75px;

}
/* break point for 600  */
@media (max-width: 600px) {
    .footer-section{   padding: 50px 35px 20px 38px;}}

@media (min-width: 600px) and (max-width: 767px) {
    
.footer-section{
     padding: 50px 70px 20px 74px;
  }
}


/* breakpoint for 768 */
@media (max-width: 768px) {
   
.footer-section{

    background-color: rgb(51, 122, 183);
    display: flex;
    flex-direction: column;
    gap: 41px;
}
.logo-sec{
    display:flex;
    flex-direction: row;
    gap:20px;
        }
        .fnt-size{
            font-size: 14px;
        }
}

/* break point for 877  */
@media (min-width:877px) {
    .service-section{
    flex: 1;

    }
    
}
@media (max-width: 877px) {

    .footer-2{

        flex-wrap: wrap;
    
        
    }
    .logo-sec{
    display:flex;
    flex-direction: row;
    
    }
    .fnt-size{
        font-size: 14px;
    }
}

@media (min-width: 878px) and (max-width: 900px) {
      
    .service-section{
         gap:34px
      }
    }