.filters-container {
    // margin-bottom: 8px;
    max-width: 100%;
    overflow: scroll;
    margin-bottom: 8px;

    .filters-container-header {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        overflow: auto;
        // padding-top: 4px;
        flex-wrap: wrap;
        gap: 10px;
        .left-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
        }
        .right-content {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            gap: 10px;
        }
        
    }
}

.filters-container-header::-webkit-scrollbar {
    display: none;
}

.filters-container::-webkit-scrollbar {
    display: none;
}