
.dashboard-card-container {
  background-color: white;
  padding: 5px 8px;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
  border-radius: 4px;
  margin-right: 9px;
  cursor: grab;
  border-left: 4px solid #2e79f8;

  .row-details {
    display: flex;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 6px;
    margin-bottom: 6px;
    .avatar {
      width: 25px;
      min-width: 25px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 8px;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .basic-info {
      width: 86%;
      position: relative;
      .patient-name{
       overflow: hidden;
       width: 64%;
       text-overflow: ellipsis;
       text-transform: capitalize;
      }

      .row-name {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        .name,
        .visit {
          display: inline-block;
          padding-right: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .name,
        .time {
          font-size:12px;
          color: #303030;
          font-weight: 700;
        }
        .name{
          width: 81%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: 5px;
            position: absolute;
            right: 30px;
            top: 1px;
        }
      }

      .row-time {
        color: #9D9D9D;
        font-size: 0.61rem;

        .dot-separator {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: white;
          border-radius: 50%;
          margin: 2px 3px;
        }
        .time-stamp {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .row-payment {
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > div {
      line-height: 14px;
    }

    .key {
      color: #303030;
        font-size: 0.63rem;
    }

    .value {
      font-size: 12px;
      color: blue;
      font-weight: 500;
    }
  }

  .row-doctor{
    display: flex;
    justify-content: space-between;
    .key {
      color: #303030;
      font-size: 0.63rem;
    }

    .value {
      font-size:10px;
      color: #9D9D9D;
      font-weight: 700;
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }
  .row-technician {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    .key {
      color: #748aac;
      font-size: 0.63rem;
    }

    .value {
      font-size:12px;
      color: #9D9D9D;
      font-weight: 700;
      padding-left: 4px;
    }
  }
  &.posi-relative {
    .sprite-img-before.appointment-info {
      position: absolute;
      right: 25px;
      bottom: 15px;
    }
  }

}

.hide-card-container {
  opacity: 0.4;
}

.legend-10min {
  border-left: 4px solid green;
}
.legend-20min {
  border-left: 4px solid yellow;
}
.legend-30min {
  border-left: 4px solid orange;
}
.legend-New {
  border-left: 4px solid blue;
}
.legend-Cancel {
  border-left: 4px solid red;
}


.activity-log-table {
  .app-table {
    max-height: 200px;
    overflow: auto;
  }
}


.card_bottomcard_outer-wrapper {
  display: flex;
  justify-content: space-between;
  .card_event-wrapper {
    display: flex;
   
    align-items: center;
  }
}
