.formBuilder-container {
  .rowGroup-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 19px;
  }
  .editable-field {
    display: flex;
    position: relative;
    padding: 8px;
    justify-content: space-between;
    cursor: pointer;
    .edit-field-icons {
      display: flex;
      position: absolute;
      right: 0;
      top: -10px;
      visibility: hidden;
    }
    .drag-icon-wrapper {
      cursor: pointer;
      padding: 6px;
      height: 36px;
      background: grey;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .drag-icon{
      visibility: hidden;
    }
  }
  .editable-field:hover {
    // border: 1px dotted;
    // border-radius: 4px;
    .edit-field-icons {
      visibility: visible;
    }
    .drag-icon {
      visibility: visible;
    }
  }
}
