.dashboard-container{
    .calendar-header-filter-wrapper{
        flex-wrap: wrap;
    }
    .widgets-container{
        display: flex;
        flex-wrap: wrap;
    }
    .appointment-panel{
        display: flex;
        align-items: flex-start;
        overflow-x: auto;
    }
}
.dashboard-drag-layer{
    position: absolute;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top:0;
    width:100%;
    height: 100%;
}