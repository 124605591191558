.toggle-Buttons-wrapper {
  min-width: 252px;

  .toggle_button {
    box-sizing: border-box;
    height: 36px;
    border-radius: 8px !important;
    margin-right: 9px;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;

    .button-label {
      margin: 5;

      .toggleButton-start-icon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border-radius: 4px;
        padding: 2px;
        align-items: center;
      }
    }

    .count {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      margin-left: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
