.calendar-view-container {
    .fc-event {
        padding-left: 12px;
        color: #fff;
        font-family: Poppins, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
    }

    .fc-event-main {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .calendar-event{
        height: 100%;
        display: flex;
        justify-content:center;
        align-items: center;
    }

    .calendar-event p {
        height:100%
    }

    .fc-daygrid-event {
        height: 15px;
    }

    .fc-daygrid-day-events {
        .fc-daygrid-event-harness {
            margin-top: 4px !important;
            .calendar-event{
                p{
                    margin: auto;
                    text-transform: capitalize;
                }
            }
        }
    }

    .event-missed {
        background-color: #ff0000;
    }

    .event-pending {
        background-color: #ffc0cb;
    }

     .event-cancelled {
        background-color:#0000ff;
    }

     .event-confirmed {
        background-color:#34B239;
    }
    .event-checkIn{
        background-color:#FF9500;
    }
    .event-readyForPractitioner{
        background-color: #808000;
    }
    .event-completed{
        background-color:#808080;
    }
    .event-waitingRoom{
        background-color:#800080;
    }

    table {
        thead {

            .fc-theme-standard td,
            .fc-theme-standard th {
                border: none !important
            }

            .fc-col-header {
                height: 61px;
                padding: 0px 8px 8px 8px;
                align-items: flex-start;
                flex-shrink: 0;
                // border-radius: 8px;
                // border: 1px solid rgba(17, 17, 17, 0.10);
                background: #E3F5FF;
                color: #636363;
                text-align: center;

                .fc-col-header-cell-cushion {
                    padding: 16px 10px;
                    font-family: poppins, sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22.4px;
                }
            }
        }

        tbody {
            .fc-day {
                border: 0.5px solid #EAF0F7;

                .fc-daygrid-day-top {
                    display: flex;
                    flex-direction: column;

                    .fc-daygrid-day-number {
                        padding: 12px 6px;
                        color: #111;
                        font-family: Poppins, sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                    }
                }
            }

            .fc-timegrid-axis-frame {
                height: 61px;
                padding: 0px 8px 8px 8px;
                align-items: flex-start;
                flex-shrink: 0;
                color: #636363;
                text-align: center;

                .fc-timegrid-axis-cushion {
                    padding: 16px 10px;
                    color: #636363;
                    font-family: poppins, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22.4px;
                    text-transform:capitalize ;
                }
            }

            .fc-timegrid-slot-label-frame {
                flex-shrink: 0;
                color: #636363;

                .fc-timegrid-slot-label-cushion {
                    color: #636363;
                    font-family: poppins, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22.4px;
                }

            }
        }
    }
}