/* Hide the close and print button in print mode */
/* styles.css */

/* @media print {
  body * {
    visibility: hidden;
  }

  #printableArea, #printableArea * {
    visibility: visible;
  }

  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .btn {
    display: none;
  }
} */

@media print {
  .print-logo {
    display: block !important;
  }
  .no-print {
    display: none !important; 
  }
}

/* Hide the logo in normal view */
.print-logo {
  display: none;
}

@media print {

  body {
    -webkit-print-color-adjust: exact; 
  }
}