.form-appointment {
    position: relative;
    .appointment-form-disable {
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  